document.body.onload = function () {


  //----------------------variables------------------------
  const cantidadImg = 34;
  let imagenes = [];
  const DateTime = luxon.DateTime;
  const anio = DateTime.now().year;
  const link = "https://www.linkedin.com/in/correa-alejandro-ezequiel-dev92/";

  for (let index = 1; index <= cantidadImg; index++) {
    imagenes.push(`img (${index})`);
  }

  //----------------funciones------------------------

  function agregarCarrusel(lista) {
    let galeria = document.getElementById("contenedor_galeria");

    let div = document.createElement("div");
    div.classList.add('carousel');
    div.classList.add('slide');
    div.setAttribute('id','carouselGaleria');
    div.setAttribute("data-bs-ride","carousel");
    div.innerHTML = ` 
    <div class="carousel-inner" id='carousel-inner'></div> 
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselGaleria" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>

  <button class="carousel-control-next" type="button" data-bs-target="#carouselGaleria" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
    `;
    galeria.append(div);
    agregarImg(lista);
  } //agregarGaleria

  function agregarImg(arreglo) {
      let carousel = document.getElementById('carousel-inner');
      arreglo.forEach((element,index) => {

        let div = document.createElement('div');
        div.classList.add('carousel-item');

        if (index==0) {
            div.classList.add('active');
        }
        
        div.setAttribute('data-bs-interval','3000');
        div.innerHTML = `<img loading="lazy"  class="d-block " src="./build/img/${element}.webp" alt="${element}">
                        `;

      carousel.append(div);
    });
  }//agregarImg

  function agregarFooter(fecha,link){
    let footer = document.getElementById('contenedor_footer');
    footer.innerHTML = `<div id="contenedor_derechos"><p>Los servicios que presta JULIAN & VARELA están sujetos a disponibilidad técnica y geográfica dentro del territorio de la República Argentina 2020-${fecha}. Todos los derechos reservados - Ciudad de Bs. As.</p></div>
     
    <a href=${link} class="animate__heartBeat"><i class="fa-solid fa-code fa-fade"></i>By Correa Alejandro</a>
    `;
  }// agregarFooter


  //--------------------programa principal----------------------
  
  agregarCarrusel(imagenes);
  agregarFooter(anio,link);
}; //onload
